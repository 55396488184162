import {Country, Product} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Typography} from '@hconnect/uikit'
import {InputTextField} from '@hconnect/uikit/src/lib2'
import {yupResolver} from '@hookform/resolvers/yup'
import {Box, CircularProgress} from '@mui/material'
import React, {useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {InternalErrors} from '../../ErrorMap'
import {useGlobalState} from '../../hooks/useGlobalState'
import {useStyles} from '../../Pages/CreateAccount/CreateAccount.styles'

import {CountryField} from './CountryField'
import {CustomerNumberField} from './CustomerNumberField'
import {LinkToSignIn} from './LinkToSignIn'
import {MarketField} from './MarketField'
import {MobilePhoneField} from './MobilePhoneField'
import {PasswordInput} from './Password'
import {SubmitButton} from './SubmitButton'
import {TermsAndConditions} from './TermsAndConditions'
import {AccessFormType, AccountFormTypes, AccountFormValues} from './types'
import {useAvailableCountries} from './useCountries'

const calcMobileNumberSchema = (clientId: Product) => {
  if (clientId !== Product.Hub && clientId !== Product.OnSite) {
    return yup.string()
  }

  return yup.string().when('email', {
    is: '',
    then: (s) => s.required(InternalErrors.PHONE_OR_EMAIL_REQUIRED)
  })
}

const calcEmailSchema = (clientId: Product) => {
  if (clientId !== Product.Hub && clientId !== Product.OnSite) {
    return yup.string().email()
  }

  return yup
    .string()
    .email()
    .when('mobileNumber', {
      is: '',
      then: (s) => s.required(InternalErrors.PHONE_OR_EMAIL_REQUIRED)
    })
}

const calcPasswordSchema = (formType: AccessFormType) => {
  const hasAtLeastOneDigit = /\d.*/
  const hasAtLeastOneLetter = /\D.*/

  if (formType === 'CREATE_ACCOUNT') {
    return yup.string().required().min(7).matches(hasAtLeastOneDigit).matches(hasAtLeastOneLetter)
  }

  return yup.string()
}

const calcAccountNumberSchema = (formType: AccessFormType) => {
  if (formType === 'REQUEST_ACCESS') {
    return yup.string().required(InternalErrors.FIELD_REQUIRED)
  }

  return yup.string()
}

const calcSchema = (clientId: Product, formType: AccessFormType) =>
  yup.object().shape(
    {
      fullName: yup.string().required(InternalErrors.FIELD_REQUIRED),
      country: yup.string().required(InternalErrors.FIELD_REQUIRED),
      market: yup.string().required(InternalErrors.FIELD_REQUIRED),
      company: yup.string().required(InternalErrors.FIELD_REQUIRED),
      mobileNumber: calcMobileNumberSchema(clientId),
      email: calcEmailSchema(clientId),
      password: calcPasswordSchema(formType),
      accountNumber: calcAccountNumberSchema(formType)
    },
    [['email', 'mobileNumber']]
  )

type Props = {
  onSubmit: (
    formValues: AccountFormValues,
    formMethods: AccountFormTypes,
    termsVersion: string,
    selectedCountry: Country
  ) => Promise<void>
  formType: AccessFormType
}

export const AccountForm: React.FC<Props> = ({onSubmit, formType}) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const {globalState} = useGlobalState()
  const [loading, setLoading] = useState<boolean>(false)
  const [termsVersion, setTermsVersion] = useState<string | null>(null)

  const formMethods = useForm<AccountFormValues>({
    defaultValues: {
      fullName: '',
      country: globalState.country || '',
      market: '',
      company: '',
      accountNumber: '',
      email: '',
      mobileNumber: '',
      additionalInfo: '',
      password: '',
      twoFactorEnabled: false
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(calcSchema(globalState.clientId, formType))
  })

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {register, control} = formMethods

  const availableCountries = useAvailableCountries()

  const {ref: fullNameRef, ...fullNameRest} = register('fullName')
  const {ref: companyRef, ...companyRest} = register('company')
  const {ref: emailRef, ...emailRest} = register('email')

  if (!availableCountries) {
    return (
      <div className={classes.loadingIndicatorContainer}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <form
      style={{display: 'flex', flexDirection: 'column', gap: '16px'}}
      onSubmit={formMethods.handleSubmit(async (formValues) => {
        const selectedCountry = availableCountries.find(
          (country) => country.countryCode === formValues.country
        )
        const accountNumberRegex = /(\d+)/gm
        const accountNumber: string =
          formValues.accountNumber.match(accountNumberRegex)?.join(' ') || ''

        const dialCode = window.intlTelInputGlobals
          .getCountryData()
          .find((country) => country.iso2.toLowerCase() === formValues.country.toLowerCase())
          ?.dialCode

        const mobileNumber =
          formValues.mobileNumber.slice(1) === dialCode ? '' : formValues.mobileNumber

        if (loading || !termsVersion || !selectedCountry) return

        const enhancedFormValues = {...formValues, accountNumber, mobileNumber}
        try {
          setLoading(true)
          await onSubmit(enhancedFormValues, formMethods, termsVersion, selectedCountry)
        } catch (e) {
          console.log(e)
        }

        trackEvent('hubExportClick', {
          product: 'hub',
          channel: globalState.hubOrigin
        })

        setLoading(false)
      })}
    >
      <InputTextField
        id="fullNameField"
        inputRef={fullNameRef}
        error={!!formMethods.formState.errors.fullName}
        label={t('authenticator.requestAccess.textfieldLabels.fullName')}
        type="text"
        required
        {...fullNameRest}
      />

      <InputTextField
        id="companyField"
        inputRef={companyRef}
        error={!!formMethods.formState.errors.company}
        label={t('authenticator.requestAccess.textfieldLabels.company')}
        type="text"
        required
        {...companyRest}
      />
      <Controller
        name="country"
        control={control}
        render={({field: {value, onChange}}) => (
          <CountryField
            name="country"
            value={value}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              formMethods.setValue('market', '')
              onChange(e)
            }}
            formMethods={formMethods}
          />
        )}
      />
      <Controller
        name="market"
        control={formMethods.control}
        render={({field: {value, onChange}}) => (
          <MarketField name="market" value={value} onChange={onChange} formMethods={formMethods} />
        )}
      />

      {formType === 'REQUEST_ACCESS' && (
        <CustomerNumberField name="accountNumber" formMethods={formMethods} />
      )}

      <Typography
        variant="h3"
        color="inherit"
        gutterBottom
        style={{
          marginTop: '34px',
          textTransform: 'none',
          color: 'rgba(78, 86, 94, 1)',
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: '24px'
        }}
      >
        {t('authenticator.requestAccess.signInOptions')}
      </Typography>

      <Box>
        <Controller
          name="mobileNumber"
          control={formMethods.control}
          render={({field: {value, onChange, name, ref}}) => (
            <MobilePhoneField
              ref={ref}
              id="mobileNumberField"
              name={name}
              initialCountry={formMethods.getValues().country}
              value={value}
              onChange={onChange}
              formMethods={formMethods}
              required={globalState.clientId === Product.OnSite}
            />
          )}
        />
      </Box>

      <InputTextField
        id="emailField"
        inputRef={emailRef}
        error={!!formMethods.formState.errors.email}
        label={t('authenticator.requestAccess.textfieldLabels.email')}
        required={globalState.clientId === Product.Hub}
        type="email"
        helperText={
          !!formMethods.formState.errors.email &&
          t(`authenticator.errorMessages.${formMethods.formState.errors.email?.message}`)
        }
        {...emailRest}
      />

      {formType === 'CREATE_ACCOUNT' && (
        <>
          <Controller
            name="password"
            control={formMethods.control}
            render={({field: {value, onChange}}) => (
              <PasswordInput
                name="password"
                value={value}
                onChange={onChange}
                formMethods={formMethods}
              />
            )}
          />
        </>
      )}
      <TermsAndConditions
        formType={formType}
        formMethods={formMethods}
        setTermsVersion={setTermsVersion}
      />

      <Box
        display="flex"
        width="100%"
        marginTop="20px"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <SubmitButton loading={loading} disabled={!termsVersion} formType={formType} />
      </Box>

      <LinkToSignIn />
    </form>
  )
}
