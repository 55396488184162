import {Typography} from '@hconnect/uikit'
import {Box, Button} from '@mui/material'
import queryString from 'query-string'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from 'tss-react/mui'

import {PaperTitle} from '../../Components/PaperTitle'
import {RedirectReason} from '../../constants'
import {useGlobalState} from '../../hooks/useGlobalState'

const useStyles = makeStyles()(() => ({
  submitButton: {
    padding: '13px 16px',
    width: '100%',
    textTransform: 'none',
    border: '1px solid #016AD4',
    borderRadius: '6px',
    backgroundColor: '#016AD4',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.08)',
    color: '#FFFFFF',
    '&.Mui-disabled': {
      color: '#FFFFFF',
      opacity: 0.3,
      border: '1px solid rgba(1, 106, 212, 1)'
    },
    '&:hover': {
      backgroundColor: 'rgba(1, 106, 212, 1)',
      opacity: 0.3
    }
  }
}))

export const Confirmation = () => {
  const {classes} = useStyles()
  const {t} = useTranslation()
  const {globalState} = useGlobalState()

  const redirectToOrigin = () => {
    const [url, paramsString] = globalState.redirectUrl.href.split('?', 1)
    const urlParams = queryString.parse(paramsString)
    const redirectReason: RedirectReason = 'REQUEST_ACCESS'
    const encodedRedirectParameters = queryString.stringify({
      ...urlParams,
      redirect_reason: redirectReason
    })

    const redirectUrl = `${url}?${encodedRedirectParameters}`
    window.location.href = redirectUrl
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
    >
      <Box>
        <PaperTitle
          title={t('authenticator.requestAccess.confirmationPage.title')}
          subtitle={t('authenticator.requestAccess.confirmationPage.subTitle')}
          isCentered={false}
        />
        <Typography variant="body2" color="inherit" gutterBottom align="left">
          {t('authenticator.requestAccess.confirmationPage.infoText')}
        </Typography>
      </Box>

      <Button
        className={classes.submitButton}
        onClick={redirectToOrigin}
        variant="outlined"
        color="primary"
        size="large"
      >
        {t('authenticator.requestAccess.confirmationPage.button')}
      </Button>
    </Box>
  )
}
