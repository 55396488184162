const allowedMobileProtocol = new Set<string>()
// onsite iOS and Android App deep links
allowedMobileProtocol.add('hconnectonsiteauthorization:')
allowedMobileProtocol.add('hconnectonsiteauthorizationqa:')
allowedMobileProtocol.add('hconnectonsiteauthorizationdev:')

const allowedRedirectUrls = new Set<string>()
// localhost development domains
allowedRedirectUrls.add('http://localhost')
allowedRedirectUrls.add('https://localhost')
allowedRedirectUrls.add('http://hconnect.digital')
allowedRedirectUrls.add('http://127.0.0.1')
allowedRedirectUrls.add('https://127.0.0.1')

// QA CDN
allowedRedirectUrls.add('https://hconnecthub-qa.azureedge.net')
allowedRedirectUrls.add('https://hconnectmanage-qa.azureedge.net')
allowedRedirectUrls.add('https://hconnecttmon-qa.azureedge.net')
allowedRedirectUrls.add('https://hconnecthcem-qa.azureedge.net')
allowedRedirectUrls.add('https://hconnectinsight-qa.azureedge.net')
allowedRedirectUrls.add('https://hconnectcockpit-qa.azureedge.net')
allowedRedirectUrls.add('https://hconnectpredict-qa.azureedge.net')
allowedRedirectUrls.add('https://hconnectpom-qa.azureedge.net')
allowedRedirectUrls.add('https://hconnecthproduce-qa.azureedge.net')
allowedRedirectUrls.add('https://hconnectslot-qa.azureedge.net')
allowedRedirectUrls.add('https://hconnectinvitation-qa.azureedge.net')
allowedRedirectUrls.add('https://hconnectmaintain-qa.azureedge.net')
allowedRedirectUrls.add('https://hconnectsettings-qa.azureedge.net')

// QA CDN storage
allowedRedirectUrls.add('https://hconnecthubqa.z6.web.core.windows.net')
allowedRedirectUrls.add('https://hconnectadminqa.z6.web.core.windows.net')
allowedRedirectUrls.add('https://hconnecttmonqa.z6.web.core.windows.net')
allowedRedirectUrls.add('https://hconnecthcemqa.z6.web.core.windows.net')
allowedRedirectUrls.add('https://hconnectinsight.z6.web.core.windows.net')
allowedRedirectUrls.add('https://hconnectcockpit.z6.web.core.windows.net')
allowedRedirectUrls.add('https://hconnectpredict.z6.web.core.windows.net')
allowedRedirectUrls.add('https://hconnectpom.z6.web.core.windows.net')
allowedRedirectUrls.add('https://hconnecthproduce.z6.web.core.windows.net')
allowedRedirectUrls.add('https://hconnectslotqa.z6.web.core.windows.net')
allowedRedirectUrls.add('https://hconnectinvitation.z6.web.core.windows.net')
allowedRedirectUrls.add('https://hconnectmaintain.z6.web.core.windows.net')
allowedRedirectUrls.add('https://hconnectsettings.z6.web.core.windows.net')

// QA Predict Clinker Quality Panel Apps
allowedRedirectUrls.add(
  'https://hpred-panel-clinker-quality-qa.hdigital-dev-emea-001.internal.aks.hcag.dev'
)
allowedRedirectUrls.add(
  'https://hpred-panel-clinker-quality-qa.hdigital-dev-nam-001.internal.aks.hcag.dev'
)
allowedRedirectUrls.add(
  'https://hpred-panel-clinker-quality-qa.hdigital-dev-apac-001.internal.aks.hcag.dev'
)

// QA Predict Cement Quality Panel Apps
allowedRedirectUrls.add(
  'https://hpred-panel-cement-quality-qa.hdigital-dev-emea-001.internal.aks.hcag.dev'
)
allowedRedirectUrls.add(
  'https://hpred-panel-cement-quality-qa.hdigital-dev-nam-001.internal.aks.hcag.dev'
)
allowedRedirectUrls.add(
  'https://hpred-panel-cement-quality-qa.hdigital-dev-apac-001.internal.aks.hcag.dev'
)

// UAT Predict Clinker Quality Panel Apps
allowedRedirectUrls.add(
  'https://hpred-panel-clinker-quality-uat.hdigital-dev-emea-001.internal.aks.hcag.dev'
)
allowedRedirectUrls.add(
  'https://hpred-panel-clinker-quality-uat.hdigital-dev-nam-001.internal.aks.hcag.dev'
)
allowedRedirectUrls.add(
  'https://hpred-panel-clinker-quality-uat.hdigital-dev-apac-001.internal.aks.hcag.dev'
)

// UAT Predict Cement Quality Panel Apps
allowedRedirectUrls.add(
  'https://hpred-panel-cement-quality-uat.hdigital-dev-emea-001.internal.aks.hcag.dev'
)
allowedRedirectUrls.add(
  'https://hpred-panel-cement-quality-uat.hdigital-dev-nam-001.internal.aks.hcag.dev'
)
allowedRedirectUrls.add(
  'https://hpred-panel-cement-quality-uat.hdigital-dev-apac-001.internal.aks.hcag.dev'
)

// PROD Predict Clinker Quality Panel Apps
allowedRedirectUrls.add(
  'https://hpred-panel-clinker-quality-prod.hdigital-prod-emea-001.internal.aks.hcag.dev'
)
allowedRedirectUrls.add(
  'https://hpred-panel-clinker-quality-prod.hdigital-prod-nam-001.internal.aks.hcag.dev'
)
allowedRedirectUrls.add(
  'https://hpred-panel-clinker-quality-prod.hdigital-prod-apac-001.internal.aks.hcag.dev'
)

// PROD Predict Cement Quality Panel Apps
allowedRedirectUrls.add(
  'https://hpred-panel-cement-quality-prod.hdigital-prod-emea-001.internal.aks.hcag.dev'
)
allowedRedirectUrls.add(
  'https://hpred-panel-cement-quality-prod.hdigital-prod-nam-001.internal.aks.hcag.dev'
)
allowedRedirectUrls.add(
  'https://hpred-panel-cement-quality-prod.hdigital-prod-apac-001.internal.aks.hcag.dev'
)
allowedRedirectUrls.add('https://grpsapdz3.grouphc.net')
allowedRedirectUrls.add('https://a65zwbsp5.accounts.ondemand.com')
// production domains
const domains = [
  'kakanjcement.ba',
  'heidelbergcement.com',
  'heidelbergcement.de',
  'heidelbergcement.ge',
  'lehighhanson.com',
  'hconnect.digital',
  'hproduce.digital',
  'hcrus.ru',
  'heidelbergcement.ru',
  'hanson.com.au',
  'hanson.co.uk',
  'hymix.com.au',
  'concrete.hk',
  'gorazdze.pl',
  'mebin.nl',
  'interbeton.be',
  'dkbeton.dk',
  'cbr.be',
  'enci.nl',
  'norbetong.no',
  'betongindustri.se',
  'gsm-granulats.fr',
  'unibeton.fr',
  'heidelbergmaterials.us',
  'heidelbergmaterials.ca',
  'heidelbergmaterials.dk',
  'heidelbergmaterials.se',
  'heidelbergmaterials.no',
  'heidelbergmaterials.de',
  'heidelbergmaterials.fr',
  'heidelbergmaterials.ro',
  'heidelbergmaterials.com',
  'pnq.com.au',
  'heidelbergmaterials.co.uk',
  'heidelbergmaterials.it',
  'heidelbergmaterials.be',
  'heidelbergmaterials.nl',
  'heidelbergmaterials.bg',
  'heidelbergmaterials.ba',
  'heidelbergmaterials.pl',
  'heidelbergmaterials-benelux.com',
  'mqp.co.uk',
  'emea-sgwext-test.heidelbergcement.com',
  'grpsapdz3.grouphc.net',
  'a65zwbsp5.accounts.ondemand.com'
]

const allowedIdentityServerDomains = new Set<string>()

allowedIdentityServerDomains.add('heidelbergcement.com')

export const isValidIdentityServer = (url: null | URL): boolean => {
  if (!url || !url.hostname) {
    return false
  }

  if (process.env.NODE_ENV === 'development' && url.hostname === 'localhost') {
    return true
  }

  // TODO: extend the check
  for (const domain of allowedIdentityServerDomains) {
    if (url.hostname.endsWith(`.${domain}`) && url.protocol === 'https:') {
      return true
    }
  }
  return false
}

export const isValidRedirectUrl = (url: URL): boolean => {
  try {
    if (allowedMobileProtocol.has(url.protocol)) {
      return true
    }

    const baseUrl = `${url.protocol}//${url.hostname}`
    if (allowedRedirectUrls.has(baseUrl)) {
      return true
    }

    for (const domain of domains) {
      if (url.hostname.endsWith(`.${domain}`) && url.protocol === 'https:') {
        return true
      }
    }
  } catch {
    console.error('Redirect URL is Invalid')
  }
  return false
}
