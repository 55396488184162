import {trackEvent} from '@hconnect/common/logging/Analytics'
import {useBreakPoints} from '@hconnect/uikit'
import {getOssAttributionLink, HProduceAccountDropdown} from '@hconnect/uikit/src/lib2'
import {Box} from '@mui/material'
import {AxiosError} from 'axios'
import jwtDecode from 'jwt-decode'
import {get} from 'lodash'
import {useSnackbar} from 'notistack'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {api} from '../../api/api'
import {login, loginForAuthCode} from '../../api/user'
import {getErrorKey} from '../../errorHandling'
import {useGlobalState} from '../../hooks/useGlobalState'
import {isFormError} from '../../Pages/SignIn/SignIn'
import {formatIdentityServerReturnUrl} from '../../Pages/SignIn/utils/formatIdentityServerReturnUrl'
import {routes} from '../../routes'
import {createOauthObject} from '../../utils'
import {calcLogoFromProductName} from '../ProductAndBrandingLogos'

export const AuthHeader = () => {
  const history = useHistory()
  const {enqueueSnackbar} = useSnackbar()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const {globalState} = useGlobalState()
  const {t} = useTranslation()
  const LogoProduct = calcLogoFromProductName(globalState.clientId.toLowerCase())

  const handleError = (errorKey: string) => {
    if (isFormError(errorKey)) {
      return
    }
    enqueueSnackbar(t(`authenticator.errorMessages.${errorKey}`), {
      variant: 'error'
    })
  }
  const onPasswordSubmit = async () => {
    const {username, password, identityServerReturnUrl} = globalState

    try {
      if (identityServerReturnUrl) {
        const validIdentityServerUrl = formatIdentityServerReturnUrl(
          identityServerReturnUrl instanceof URL
            ? identityServerReturnUrl
            : new URL(identityServerReturnUrl)
        )

        const authCodeResponse = await loginForAuthCode(api)(
          username,
          password,
          validIdentityServerUrl
            ? validIdentityServerUrl.toString()
            : identityServerReturnUrl.toString(),
          false,
          null,
          null,
          true
        )

        globalState.identityServerReturnUrl = authCodeResponse.idsReturnUrl

        if (authCodeResponse.isPasswordChangeRequired) {
          history.push(routes.ChangePassword)
          return
        }
      } else {
        const oauthParams = createOauthObject(globalState)
        const tokenInfo = await login(api)(username, password, oauthParams)

        let isPasswordChangeRequired = false
        const decoded = jwtDecode(tokenInfo.access_token)
        isPasswordChangeRequired =
          get(decoded, ['isPasswordChangeRequired'], 'false').toLowerCase() === 'true'

        if (isPasswordChangeRequired) {
          history.push(routes.ChangePassword)
          return
        }

        globalState.token = tokenInfo
      }

      history.push(routes.RequestUserDataVerification)
    } catch (error) {
      const e = error as AxiosError

      console.error(e)
      trackEvent('authError', {
        product: 'authenticator',
        date: new Date().toISOString(),
        errorCode: e.response?.status,
        component: 'StepChangePasswordSuccess.tsx',
        endpoint: e.response?.config.url
      })

      handleError(getErrorKey(e))
    }
  }

  return (
    <Box style={{width: '100%'}}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}
      >
        {!isMobile && (
          <img
            src={LogoProduct}
            style={{maxWidth: '112px', height: '40px'}}
            alt="HConnect"
            data-test-id="logo-hconnect"
          />
        )}
        {isMobile && (
          <img
            src={LogoProduct}
            style={{maxWidth: '112px', height: '40px'}}
            alt="HConnect"
            data-test-id="logo-hconnect"
          />
        )}
        <HProduceAccountDropdown
          imprintText={t('authenticator.actions.imprint')}
          osSoftwareAttributionText={t('authenticator.actions.osSoftwareAttribution')}
          signInText={t('authenticator.actions.signIn')}
          actions={{
            imprint: () => {
              window.location.href = `${process.env.REACT_APP_LEGAL_URL}/policies/imprint/${globalState.country}`
            },
            osSoftwareAttribution: () => {
              window.location.href = getOssAttributionLink()
            },
            signIn: () => {
              window.location.pathname === routes.ChangePasswordSuccess ||
              window.location.pathname === routes.CreateAccountSuccess
                ? void onPasswordSubmit()
                : (window.location.href = globalState.redirectUrl.toString())
            }
          }}
        />
      </Box>
    </Box>
  )
}
