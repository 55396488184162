import {Channel, passwordlessStart, PasswordlessStartParams} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {getLocale} from '@hconnect/uikit'
import {InputErrorMessage, InputTextField} from '@hconnect/uikit/src/lib2'
import {CircularProgress, Link, Box, Button} from '@mui/material'
import classNames from 'classnames'
import {useSnackbar} from 'notistack'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {api} from '../../api/api'
import LoadingButton from '../../Components/LoadingButton'
import {PaperTitle} from '../../Components/PaperTitle'
import {UsernameGroup} from '../../Components/UsernameGroup/UsernameGroup'
import {clientSecrets, LoginType} from '../../constants'
import {useGlobalState} from '../../hooks/useGlobalState'
import {routes} from '../../routes'

import {useStyles} from './SignIn.styles'

interface StepPasswordProps {
  passwordTextFieldRef: React.MutableRefObject<HTMLInputElement | undefined>
  error: string | null
  mfaError: string | null
  isLoading: boolean
  onPasswordSubmit(): Promise<void>
  location: any
  setError: React.Dispatch<React.SetStateAction<string | null>>
}

export const StepPassword: React.FC<StepPasswordProps> = ({
  error,
  mfaError,
  isLoading,
  passwordTextFieldRef,
  onPasswordSubmit,
  location,
  setError
}) => {
  const {classes} = useStyles()
  const {t} = useTranslation()
  const history = useHistory()
  const {enqueueSnackbar} = useSnackbar()
  const {globalState, setGlobalState} = useGlobalState()

  const [isResetPasswordLoading, setResetPasswordLoading] = useState(false)

  const resetPasswordPasswordless = async () => {
    const {clientId, email, username, redirectUrl, country, loginType} = globalState

    const params: PasswordlessStartParams = {
      client_id: clientId,
      client_secret: clientSecrets[clientId],
      mobile_number: loginType === LoginType.PHONE ? username : null,
      email: loginType === LoginType.EMAIL ? email : null,
      redirect_uri: redirectUrl.href,
      country_code: country,
      channel: loginType === LoginType.EMAIL ? Channel.EMAIL : Channel.SMS,
      product: clientId,
      request_locale: getLocale(),
      reset_password: true,
      type: 'code'
    }

    setResetPasswordLoading(true)

    const result = await passwordlessStart(api)(params)

    setResetPasswordLoading(false)

    if (result.type === 'error') {
      console.error(result.error)

      trackEvent('authError', {
        product: 'authenticator',
        date: new Date().toISOString(),
        errorCode: result.error.errorCode,
        component: 'StepPassword.tsx',
        endpoint: '/passwordless/start'
      })

      // FIXME ask BE to provide error code for this
      if (result.error.message.includes('wait')) {
        enqueueSnackbar(t('authenticator.signIn.error.quickCodeTooSoon', {variant: 'error'}))
      } else {
        enqueueSnackbar(t('authenticator.signIn.error.unknown', {variant: 'error'}))
      }
    }
    if (result.type === 'value') {
      setGlobalState((g) => ({...g, passwordlessStartToken: result.value.start_token}))
      history.push(routes.ResetPassword)
    }
  }

  const resetPasswordPasswordLegacy = () => {
    history.push(routes.ResetPasswordLegacy)
  }

  const resetPassword = async () => {
    globalState.isPasswordlessSignInEnabled
      ? void resetPasswordPasswordless()
      : resetPasswordPasswordLegacy()
  }

  useEffect(() => {
    if (error && error === '401_ACCOUNT_LOCKED') {
      history.push(routes.UserLocked)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return (
    <form
      className={classNames(classes.passwordRoot, {
        [classes.hidden]: location.pathname !== routes.Password
      })}
      data-test-id="page-signin-password"
      onSubmit={(e) => e.preventDefault()}
    >
      <PaperTitle title={t('authenticator.signIn.title')} isCentered={false} />
      <Box style={{marginBottom: '16px'}}>
        <UsernameGroup isLoading={isLoading} error={error} setError={setError} />
      </Box>

      <InputTextField
        inputRef={passwordTextFieldRef}
        value={globalState.password}
        name="password"
        isPassword={true}
        focused={true}
        autoComplete="current-password"
        data-test-id="authenticator-input-password"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setGlobalState({...globalState, password: e.target.value.trim()})
        }
        error={error === '401_LOGIN_FAILURE'}
        label={t('authenticator.password.password')}
      />
      {(error === '401_LOGIN_FAILURE' || mfaError) && (
        <InputErrorMessage
          errorMessage={error ? t('authenticator.password.incorrectPassword') : mfaError || ''}
        />
      )}

      <Box style={{marginTop: '20px'}}>
        <Box style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
          <LoadingButton
            disabled={isLoading || !globalState.password || globalState.password.length === 0}
            fullWidth
            type="submit"
            color="primary"
            variant="outlined"
            loading={isLoading}
            onClick={onPasswordSubmit}
            data-test-id="authenticator-submit-password"
          >
            {t('authenticator.password.signin')}
          </LoadingButton>

          <Button
            variant="outlined"
            data-test-id="authenticator-quick-code"
            className={classNames(classes.linkButton, classes.enabledLink, {
              [classes.disabledLink]: isLoading
            })}
            onClick={() => history.push(routes.SignIn, {intiateQuickCodeSignIn: true})}
          >
            {t('authenticator.password.goToQuickCode')}
          </Button>
        </Box>

        <Box style={{marginTop: '44px'}}>
          {isResetPasswordLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Link
              data-test-id="authenticator-reset-password"
              className={classNames(classes.linkButton, classes.enabledLink, {
                [classes.disabledLink]: isLoading
              })}
              onClick={() => {
                if (!isLoading) {
                  return resetPassword()
                }
              }}
              underline="always"
            >
              {t('authenticator.password.forgotPassword')}
            </Link>
          )}
        </Box>
      </Box>
    </form>
  )
}
