import {Channel, Product, Token} from '@hconnect/apiclient'
import queryString from 'query-string'
import React, {useState} from 'react'

import {LoginType} from '../constants'
import {isValidIdentityServer, isValidRedirectUrl} from '../whitelist'

export type RegistrationType = 'request-access' | 'create-account'

export interface GlobalState {
  email: string
  mobileNumber: string
  fullMobileNumber: string
  password: string
  country: string
  username: string
  newPassword1: string
  newPassword2: string
  clientId: Product
  redirectUrl: URL
  roleRequestId: string
  scope: string
  state: string
  loginType: LoginType
  channel: Channel
  isPasswordlessSignInEnabled: boolean | null
  isPasswordfulSignInEnabled: boolean | null
  passwordlessStartToken: string
  mobileNumberValid: boolean
  lng: string
  token: Token | null
  pwlessPasswordReset: boolean
  otp: string
  source: null | 'webview'
  webviewType: null | 'firstLoad' | 'orderCreated'
  identityServerReturnUrl: null | URL
  registrationType: RegistrationType | null
  hubOrigin?: string
}

export const validateSession = (globalState: GlobalState) => {
  const {pathname, search} = window.location
  const {redirectUrl, identityServerReturnUrl} = globalState

  return (
    isValidIdentityServer(identityServerReturnUrl) ||
    isValidRedirectUrl(redirectUrl) ||
    pathname === '/version' ||
    pathname === '/request-sent' ||
    pathname === '/verify' ||
    pathname === '/account' ||
    pathname.startsWith('/error') ||
    search.indexOf('logoutId') > -1
  )
}

const initialGlobalState = ((): GlobalState => {
  const {search} = window.location
  const intialQueryParamValues = {
    client_id: '',
    scope: 'global offline_access',
    redirect_uri: '',
    state: '',
    _email: '',
    _password: '',
    country_code: '',
    start_token: '',
    lng: '',
    channel: '',
    username: '',
    reset_password: false,
    otp: '',
    roleRequestId: '',
    // Following properties are set by OnSite, which gets them from a deeplink (hcag.app),
    // which gets it from either link directly or webview, which can appear in between.
    source: null, // Set by OnSite, may be 'webview'
    webviewType: null, // Set by OnSite, may be 'firstLoad'
    ids_return_url: '',
    hubOrigin: ''
  }
  const queryParameters: any = {
    ...intialQueryParamValues,
    ...queryString.parse(search, {parseBooleans: true})
  }

  let hubOrigin = ''

  if (queryParameters.ids_return_url) {
    const identityServerReturnUrl = new URL(queryParameters.ids_return_url)
    queryParameters.redirect_uri = ''

    const identityReturnUrlParams: any = {
      client_id: '',
      scope: 'global offline_access',
      state: '',
      redirect_uri: '',
      ...queryString.parse(identityServerReturnUrl.search, {parseBooleans: true})
    }

    if (identityReturnUrlParams.qrType) {
      queryParameters.webviewType = identityReturnUrlParams.qrType
    }

    // _phone, _email and _password variables do come from
    // OnSite - OnSite team uses this in order to prefill
    // username and password to be able to pass through
    // sign in flow more quicker in development mode.
    queryParameters._phone = identityReturnUrlParams._phone
    queryParameters._email = identityReturnUrlParams._email
    queryParameters._password = identityReturnUrlParams._password
    queryParameters.client_id = identityReturnUrlParams.client_id
    queryParameters.scope = identityReturnUrlParams.scope
    queryParameters.state = identityReturnUrlParams.state
    queryParameters.redirect_uri = identityReturnUrlParams.redirect_uri
    if (identityReturnUrlParams?.registrationType) {
      queryParameters.registrationType = identityReturnUrlParams.registrationType
    }

    hubOrigin = identityReturnUrlParams.channel
  }

  const loginTypeValue =
    queryParameters.client_id === Product.OnSite || queryParameters.channel === Channel.SMS
      ? LoginType.PHONE
      : LoginType.EMAIL

  const redirectUrl = new URL(
    queryParameters.redirect_uri ? queryParameters.redirect_uri : 'http://no-redirect-url.de'
  )

  if (queryParameters.state) {
    redirectUrl.searchParams.set('state', queryParameters.state)
  }
  const stateFromRedirectUrl = redirectUrl.searchParams.get('state') ?? ''

  const globalState: GlobalState = {
    email: queryParameters.username || queryParameters._email,
    mobileNumber: queryParameters.username || queryParameters._phone,
    fullMobileNumber: '',
    password: queryParameters._password,
    country: queryParameters.country_code,
    username: queryParameters.username,
    newPassword1: '',
    newPassword2: '',
    clientId: queryParameters.client_id as Product,
    redirectUrl,
    roleRequestId: queryParameters.roleRequestId,
    scope: queryParameters.scope,
    state: queryParameters.state || stateFromRedirectUrl,
    loginType: loginTypeValue,
    isPasswordlessSignInEnabled: null,
    isPasswordfulSignInEnabled: null,
    passwordlessStartToken: queryParameters.start_token,
    lng: queryParameters.lng,
    token: null,
    mobileNumberValid: false,
    pwlessPasswordReset: queryParameters.reset_password,
    channel: queryParameters.channel as Channel,
    otp: queryParameters.otp,
    source: queryParameters.source,
    webviewType: queryParameters.webviewType,
    identityServerReturnUrl: queryParameters.ids_return_url
      ? new URL(queryParameters.ids_return_url)
      : null,
    registrationType: queryParameters.registrationType ? queryParameters.registrationType : null,
    hubOrigin: hubOrigin
  }

  return globalState
})()

interface ContextState {
  globalState: GlobalState
  setGlobalState: React.Dispatch<React.SetStateAction<GlobalState>>
}

const GlobalStateContext = React.createContext<ContextState | undefined>(undefined)

type GlobalProviderProps = {
  children: React.ReactNode
}
export const GlobalStateProvider = ({children}: GlobalProviderProps) => {
  const [globalState, setGlobalState] = useState(initialGlobalState)
  const value = {globalState, setGlobalState}

  return <GlobalStateContext.Provider value={value}>{children}</GlobalStateContext.Provider>
}

export const useGlobalState = () => {
  const ctx = React.useContext(GlobalStateContext)
  if (ctx === undefined) {
    throw new Error('useGlobal used outside of ContextProvider!')
  } else {
    return ctx
  }
}
