/* eslint-disable max-statements */

import {Channel, verifyUserData} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Link} from '@hconnect/uikit'
import {LinearProgress} from '@mui/material'
import {AxiosError} from 'axios'
import queryString from 'query-string'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import {makeStyles} from 'tss-react/mui'

import {api} from '../../api/api'
import {PaperTitle} from '../../Components/PaperTitle'

const useStyles = makeStyles()(() => ({
  enabledLink: {cursor: 'pointer'},
  linkWrapper: {
    textAlign: 'center'
  }
}))

const BAD_REQUEST_STATUSES = new Set([400, 401, 403])

// eslint-disable-next-line complexity
const VerifyUserData: React.FC<RouteComponentProps> = ({history}) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [redirectUri, setRedirectUri] = useState<string | null>(null)
  const [type, setType] = useState<Channel | null>(null)
  const [isVerified, setVerified] = useState(false)

  const handleRedirectUri = () => {
    if (redirectUri) {
      window.location = redirectUri as any
    }
  }

  const isValidRedirectUri = () =>
    redirectUri &&
    (redirectUri.trim().startsWith('https://') || redirectUri.trim().startsWith('http://'))

  useEffect(() => {
    const verify = async () => {
      const {search} = window.location
      const queryParameters = queryString.parse(search)
      const {t: startToken = '', o: oneTimeCode = ''} = queryParameters as Record<string, string>
      const response = await verifyUserData(api)({
        start_token: startToken,
        one_time_code: oneTimeCode
      })

      if (response.type === 'error') {
        if (BAD_REQUEST_STATUSES.has(response.error.errorCode)) {
          setErrorMessage(t('authenticator.verify.invalidRequest'))
        }
      }
      if (response.type === 'value') {
        const verifyResponse = response.value
        setRedirectUri(verifyResponse.redirect_uri)
        setType(verifyResponse.type)
      }
    }

    if (!isVerified) {
      verify().catch((error) => {
        const e: AxiosError = error

        console.error(e)
        trackEvent('authError', {
          product: 'authenticator',
          date: new Date().toISOString(),
          errorCode: e.response?.status,
          component: 'VerifyUserData.tsx',
          endpoint: e.response?.config.url
        })
        setErrorMessage(t('authenticator.verify.invalidRequest'))
      })
      setVerified(true)
    }
  }, [history, t, type, errorMessage, isVerified])

  if (errorMessage) {
    return (
      <>
        <PaperTitle title="" subtitle={errorMessage} isCentered />
      </>
    )
  }

  if (type) {
    const successMessage = t(
      `authenticator.verify.${type === Channel.EMAIL ? 'emailSuccess' : 'phoneSuccess'}`
    )
    return (
      <>
        <PaperTitle title="" subtitle={successMessage} isCentered />
        {isValidRedirectUri() && (
          <div className={classes.linkWrapper}>
            <Link className={classes.enabledLink} onClick={handleRedirectUri}>
              {t('authenticator.verify.navigateToTheApp')}
            </Link>
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <LinearProgress />
      <PaperTitle title="" subtitle={t('authenticator.verify.verificationInProgress')} isCentered />
    </>
  )
}

export default withRouter(VerifyUserData)
