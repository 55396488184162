import {
  Feature,
  getUserProfile,
  isFeatureEnabled,
  requestUserDataVerification
} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {FormControl, FormHelperText, LinearProgress, Link} from '@mui/material'
import {AxiosError} from 'axios'
import queryString from 'query-string'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {api, createAuthHttpClient} from '../../api/api'
import LoadingButton from '../../Components/LoadingButton'
import {PaperTitle} from '../../Components/PaperTitle'
import {useGlobalState} from '../../hooks/useGlobalState'
import {routes} from '../../routes'
import {createOauthObject} from '../../utils'

import {redirect} from './SignIn'
import {useStyles} from './SignIn.styles'

export const StepVerifyUserData: React.FC = () => {
  const {classes} = useStyles()
  const {t} = useTranslation()
  const history = useHistory()
  const [isLoading, setLoading] = useState(false)

  const [isUserLoaded, setUserLoaded] = useState(false)
  const [isEmailError, setEmailError] = useState(false)
  const [isSmsError, setSmsError] = useState(false)
  const [requiresPhoneVerification, setRequiresPhoneVerification] = useState(false)
  const [requiresEmailVerification, setRequiresEmailVerification] = useState(false)
  const {globalState} = useGlobalState()

  const {search} = window.location
  const qs = queryString.parse(search)
  const usernameFromQueryString = qs.username as string
  const username = usernameFromQueryString ? usernameFromQueryString : globalState.username

  const onLoginSuccessful = useCallback(() => {
    const oauthParams = createOauthObject(globalState)
    const {token, identityServerReturnUrl} = globalState

    console.warn('onSuccessful')
    if (identityServerReturnUrl) {
      let identityServerReturnUrlOrigin =
        identityServerReturnUrl instanceof URL
          ? identityServerReturnUrl.origin
          : new URL(identityServerReturnUrl).origin
      if (!identityServerReturnUrlOrigin) {
        console.error('Invalid url error')

        identityServerReturnUrlOrigin = new URL(identityServerReturnUrl as any as string).origin
      }
      const relativePath = identityServerReturnUrl
        .toString()
        .substring(identityServerReturnUrlOrigin.length)

      console.warn('redirecting to relativePath')
      console.warn(relativePath)

      window.location = relativePath as any
      return
    }

    if (token === null) {
      console.warn('token is null. Redirecting to SignIn')
      history.push(routes.SignIn)
      return
    }

    redirect({
      window,
      token,
      oauthParams,
      username,
      redirectReason: 'LOGIN'
    })
  }, [globalState, history])

  const hasVerificationError = async (type: 'email' | 'sms') => {
    const {redirectUrl, token} = globalState
    if (!token?.access_token) {
      console.warn('No access_token found in token. Redirecting to sign in', token)
      history.push(routes.SignIn)
      return true
    }

    const accessToken = token.access_token
    const authApi = createAuthHttpClient(accessToken)

    const emailResponse = await requestUserDataVerification(authApi)({
      type,
      redirect_uri: redirectUrl.href
    })

    if (emailResponse.type === 'error') {
      type === 'email' ? setEmailError(true) : setSmsError(true)
      return true
    }

    return false
  }

  const requestDataVerification = async () => {
    setLoading(true)

    const hasEmailError = requiresEmailVerification && (await hasVerificationError('email'))
    const hasPhoneError = requiresPhoneVerification && (await hasVerificationError('sms'))

    if (hasEmailError || hasPhoneError) {
      return
    }

    onLoginSuccessful()
  }

  const isRequestError = () =>
    (requiresEmailVerification && isEmailError) || (requiresPhoneVerification && isSmsError)

  useEffect(() => {
    const getFeatureEnabled = async (): Promise<boolean> => {
      // TODO try get the countryId (extract from url)
      const countryId = ''

      const response = await api.get<Feature[]>('/features?tag=UserDataVerification')
      return isFeatureEnabled(response.data, {countryId}, 'UserDataVerificationToggle')
    }

    const init = async () => {
      try {
        const {token, identityServerReturnUrl} = globalState
        if (identityServerReturnUrl) {
          onLoginSuccessful()
          return
        }
        const enabled = await getFeatureEnabled()
        if (!enabled) {
          onLoginSuccessful()
          return
        }

        if (!token?.access_token) {
          history.push(routes.SignIn)
          return
        }

        const accessToken: string = token?.access_token

        const api = createAuthHttpClient(accessToken)
        const user = await getUserProfile(api)(username)

        const requiresEmailCheck = !!user.eMail && !user.isEmailVerified

        setRequiresEmailVerification(requiresEmailCheck)

        const requiresPhoneCheck = !!user.mobileNumber && !user.isMobileNumberVerified

        setRequiresPhoneVerification(requiresPhoneCheck)

        if (!requiresEmailCheck && !requiresPhoneCheck) {
          onLoginSuccessful()
          return
        }

        setUserLoaded(true)
      } catch (error) {
        const e = error as AxiosError

        console.error(e)
        trackEvent('authError', {
          product: 'authenticator',
          date: new Date().toISOString(),
          errorCode: e.response?.status,
          component: 'StepVerifyUserData.tsx',
          endpoint: e.response?.config.url
        })

        onLoginSuccessful()
        return
      } finally {
        setLoading(false)
      }
    }

    if (!isUserLoaded) {
      void init()
    }
  }, [
    history,
    t,
    onLoginSuccessful,
    requiresPhoneVerification,
    requiresEmailVerification,
    globalState,
    isUserLoaded
  ])

  if (!isUserLoaded) {
    return (
      <>
        <LinearProgress />
      </>
    )
  }

  return (
    <form
      className={classes.passwordRoot}
      data-test-id="page-request-verification"
      onSubmit={(e) => e.preventDefault()}
    >
      <PaperTitle
        title={t('authenticator.verifyUserData.title')}
        subtitle={t('authenticator.verifyUserData.subtitle')}
        isCentered={false}
      />

      <div className={classes.verifyDataExplanation}>
        {requiresPhoneVerification &&
          requiresEmailVerification &&
          t('authenticator.verifyUserData.verifyPhoneAndEmailExplanation')}
        {requiresPhoneVerification &&
          !requiresEmailVerification &&
          t('authenticator.verifyUserData.verifyPhoneExplanation')}
        {!requiresPhoneVerification &&
          requiresEmailVerification &&
          t('authenticator.verifyUserData.verifyEmailExplanation')}
      </div>

      <LoadingButton
        className={classes.submitButton}
        disabled={isLoading}
        fullWidth
        type="submit"
        color="primary"
        variant="outlined"
        loading={isLoading}
        onClick={requestDataVerification}
        data-test-id="authenticator-submit-request-verification-link"
      >
        {requiresPhoneVerification &&
          requiresEmailVerification &&
          t('authenticator.verifyUserData.requestVerificationLinks')}

        {(!requiresPhoneVerification || !requiresEmailVerification) &&
          t('authenticator.verifyUserData.requestVerificationLink')}
      </LoadingButton>
      {isRequestError() && (
        <FormControl margin="dense" variant="standard" fullWidth>
          <FormHelperText filled error data-test-id="authenticator-password-error">
            {t('authenticator.verifyUserData.errorSendingRequests')}
          </FormHelperText>
        </FormControl>
      )}
      <div className={classes.skipWrapper}>
        <Link
          className={classes.enabledLink}
          onClick={onLoginSuccessful}
          data-test-id="authenticator-skip-request-verification-link"
        >
          {t('authenticator.verifyUserData.skip')}
        </Link>
      </div>
    </form>
  )
}
