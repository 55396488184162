import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  paper: {
    height: '100%',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '580px',
    padding: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: theme.spacing(2),
      borderRadius: 0
    }
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    paddingTop: '30px'
  },
  additionalInorfmationContainer: {
    paddingTop: '30px'
  },
  submitButton: {
    padding: '13px 16px',
    width: '100%',
    textTransform: 'none',
    border: '1px solid #016AD4',
    borderRadius: '6px',
    backgroundColor: '#016AD4',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.08)',
    color: '#FFFFFF',
    '&.Mui-disabled': {
      color: '#FFFFFF',
      opacity: 0.3,
      border: '1px solid rgba(1, 106, 212, 1)'
    },
    '&:hover': {
      backgroundColor: 'rgba(1, 106, 212, 1)',
      opacity: 0.3
    }
  },
  outlinedTextField: {
    '& > div': {
      minHeight: '100px'
    }
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingTop: '30px'
  },
  loadingIndicator: {
    width: '100%',
    marginTop: 15
  },
  loadingIndicatorContainer: {
    justifyContent: 'center',
    display: 'flex'
  },
  termsCheckbox: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px'
  },
  termsCheckboxLabel: {
    cursor: 'pointer'
  },
  link: {
    cursor: 'pointer'
  }
}))
