import {Country, Feature, isFeatureEnabled, Product} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import Ajv from 'ajv'
import {AxiosError} from 'axios'
import sha1 from 'crypto-js/sha1'

import {api} from './api/api'
import {clientSecrets, EmailRegExp, PhoneRegExp} from './constants'
import {GlobalState} from './hooks/useGlobalState'

export interface OAuthObject {
  client_id: Product
  client_secret: string
  scope: string
  redirect_uri: URL
  state: string
}

export const isEmailValid = (email: string): boolean => EmailRegExp.test(email)

export const isPhoneValid = (phone: string): boolean => PhoneRegExp.test(phone)

export const hasAtLeastOneDigit = (password: string) => !!/\d.*/.exec(password)
export const hasAtLeastEightCharacters = (password: string) => !!/.{8,}/.exec(password)

export const hasAtLeastOneNonAlphanumericCharacter = (password: string) =>
  !!/.*[^a-zA-Z0-9]+.*/.exec(password)

export const hasAtLeastOneUppercaseAndOneLowercaseLetter = (password: string) =>
  !!/^(?=.*[a-z])(?=.*[A-Z]).*$/.exec(password)

export const isPasswordValid = (password: string): boolean =>
  hasAtLeastOneDigit(password) &&
  hasAtLeastEightCharacters(password) &&
  hasAtLeastOneNonAlphanumericCharacter(password) &&
  hasAtLeastOneUppercaseAndOneLowercaseLetter(password)

export const isTestMobileNumber = (mobileNumber: string): boolean =>
  mobileNumber.startsWith('+4917159')

// FIXME: everywhere CamelCase is used, replace snake_case here as well? Does it has implications to send
// CamelCased data in GET params
// rename internal params into params passed in the URL
export const createOauthObject = ({
  clientId,
  scope,
  redirectUrl,
  state
}: GlobalState): OAuthObject => ({
  client_id: clientId,
  client_secret: clientSecrets[clientId],
  scope,
  redirect_uri: redirectUrl,
  state
})

// Example: https://some.url.com/bla?asdf=asdf  => https://some.url.com/bla
export const stripUrlParams = (url: string): string => url.split('?')[0]

// consider reading https://developer.mozilla.org/Oen-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
// TODO: user agent sniffing not recommended by mozilla, but navigator.maxTouchPoints doesn't work somehow, further investigation is needed!
// code from: https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
export const mobileAndTabletCheck = (): boolean => {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[23]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.slice(0, 4)
      )
    ) {
      check = true
    }
    // @ts-expect-error FIXME
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

// FIXME unused?
export const extractTokenInfoFromJSON = (json: any) => {
  const refreshToken = json.refresh_token
  const accessToken = json.access_token
  const accessTokenType = json.token_type
  const accessTokenTimeToLiveInSeconds = json.expires_in

  const accessTokenTimeToLiveInMilliseconds = accessTokenTimeToLiveInSeconds * 1000
  const currentTimeInMilliseconds = new Date().getTime()
  const accessTokenExpiryDate = currentTimeInMilliseconds + accessTokenTimeToLiveInMilliseconds

  return {refreshToken, accessToken, accessTokenType, accessTokenExpiryDate}
}

// FIXME unused?
export const createJSONValidator = (schema: any) => {
  const ajv = new Ajv({allErrors: true, strict: 'log'})
  return ajv.compile(schema)
}
export const anonymizeText = (text: string): string => {
  const lowercaseText = text.toLowerCase()
  return sha1(lowercaseText).toString()
}

export const getBrowserLanguage = (): string => {
  return navigator.language?.split('-')[0] ?? 'en'
}

export const calcDefaultLocale = (selectedCountry: Country, preferredLanguage?: string) => {
  let matchingLocale = selectedCountry.locales.find((locale) => locale.code === preferredLanguage)

  if (matchingLocale) {
    return matchingLocale?.code ?? preferredLanguage
  }

  if (!selectedCountry || !navigator.languages) return selectedCountry.defaultLocale
  const selectedCountryWithBrowserLocale = `${getBrowserLanguage().toLowerCase()}-${selectedCountry.countryCode.toUpperCase()}`

  matchingLocale = selectedCountry.locales.find(
    (locale) => locale.code === selectedCountryWithBrowserLocale
  )

  return matchingLocale?.code ?? selectedCountry.defaultLocale
}

export const getLocale = (): string => {
  let locale: string
  if (window.navigator.languages) {
    locale = window.navigator.languages[0]
  } else {
    // @ts-expect-error FIXME
    locale = window.navigator.userLanguage || window.navigator.language
  }
  if (!locale) {
    locale = 'en-US'
  }
  return locale
}

export const preferredCountriesFromLocale = (locale: string): string[] => {
  switch (locale.toLowerCase()) {
    case 'de-de':
      return ['de', 'us', 'ca', 'au']

    case 'en-au':
      return ['au', 'us', 'ca', 'de']

    case 'en-ca':
      return ['ca', 'us', 'au', 'de']

    default:
      return ['us', 'ca', 'au', 'de']
  }
}

const unambiguousTlds = new Set(['hk', 'au', 'ru'])

export const getCountryIdsFromRedirectUrl = (url: URL): string[] => {
  // TODO handle onsite's redirect url of "hconnectonsiteauthorization%3A%2F%2F"

  if (url.origin.includes('.')) {
    const firstOriginPart = url.origin.split('.').pop() || ''
    const tld = firstOriginPart.includes(':') ? firstOriginPart.split(':').shift() : firstOriginPart

    if (tld && unambiguousTlds.has(tld)) {
      return [tld.toUpperCase()]
    }

    if (url.origin.endsWith('heidelbergcement.com') || url.origin.endsWith('hconnect.digital')) {
      return ['DE', 'BE', 'NL', 'GB', 'PL']
    }

    if (url.origin.endsWith('lehighhanson.com')) {
      return ['US', 'CA']
    }
  }

  return []
}

export const getBannerCountryIdsFromRedirectUrl = (url: URL): string[] => {
  const unambiguousBannerTlds = new Set([
    'hk',
    'au',
    'ru',
    'ba',
    'de',
    'ge',
    'uk',
    'be',
    'dk',
    'nl',
    'fr',
    'us',
    'ca',
    'se',
    'no',
    'pl'
  ])

  if (url.origin.includes('.')) {
    const firstOriginPart = url.origin.split('.').pop() || ''
    const tld = firstOriginPart.includes(':') ? firstOriginPart.split(':').shift() : firstOriginPart

    if (tld && unambiguousBannerTlds.has(tld)) {
      return [tld.toUpperCase()]
    }

    if (
      url.origin.endsWith('heidelbergcement.com') ||
      url.origin.endsWith('heidelbergmaterials.com') ||
      url.origin.endsWith('hconnect.digital') ||
      url.origin.endsWith('hproduce.digital')
    ) {
      return ['DE', 'BE', 'NL', 'GB', 'PL']
    }
  }

  return []
}

export const getCountryIdFromLng = (lng: string) => {
  const formattedLng = lng.split('-')?.[1] || lng
  return formattedLng.toUpperCase()
}

export const getSigninOptions = async (
  redirectUrl: URL,
  clientId: string,
  countryId: string
): Promise<{
  isPasswordlessSignInEnabled: boolean
  isPasswordfulSignInEnabled: boolean
}> => {
  try {
    const passwordlessResponse = await api.get<Feature[]>('/features?tag=Passwordless')

    const isPasswordlessSignInSupported = isFeatureEnabled(
      passwordlessResponse.data,
      {countryId: ''},
      'PasswordlessToggle'
    )
    if (!isPasswordlessSignInSupported) {
      return {
        isPasswordlessSignInEnabled: false,
        isPasswordfulSignInEnabled: true
      }
    }

    const countriesIds: string[] = countryId
      ? [countryId]
      : [...getCountryIdsFromRedirectUrl(redirectUrl)]

    if (countriesIds.length === 0) {
      const defaultPasswordlessEnabled = isFeatureEnabled(
        passwordlessResponse.data,
        {clientId},
        'DefaultPasswordlessProductEnabled'
      )

      return {
        isPasswordlessSignInEnabled: defaultPasswordlessEnabled,
        isPasswordfulSignInEnabled: true
      }
    }

    const loginFlowsResponse = await api.get<Feature[]>('/features?tag=loginflows')

    const allCountriesPasswordlessEnabled = countriesIds.every((countryId) =>
      isFeatureEnabled(loginFlowsResponse.data, {countryId, clientId}, 'LoginPasswordless')
    )

    const allCountriesPasswordEnabled = countriesIds.every((countryId) =>
      isFeatureEnabled(loginFlowsResponse.data, {countryId, clientId}, 'LoginWithPassword')
    )

    return {
      isPasswordlessSignInEnabled: allCountriesPasswordlessEnabled,
      isPasswordfulSignInEnabled: allCountriesPasswordEnabled || !allCountriesPasswordlessEnabled
    }
  } catch (error) {
    const e = error as AxiosError

    console.error(e)
    trackEvent('authError', {
      product: 'authenticator',
      date: new Date().toISOString(),
      errorCode: e.response?.status,
      component: 'utils.ts',
      endpoint: e.response?.config.url
    })
    return {
      isPasswordlessSignInEnabled: false,
      isPasswordfulSignInEnabled: true
    }
  }
}

export const getCRMUrl = () => {
  const env = process.env.REACT_APP_STAGE

  if (env) {
    switch (env) {
      case 'qa': {
        return 'https://hc-test.crm4.dynamics.com'
      }
      case 'uat': {
        return 'https://qa-sias.crm4.dynamics.com'
      }
      case 'prod': {
        return 'https://sias.crm4.dynamics.com'
      }
      default: {
        return 'https://hc-test.crm4.dynamics.com'
      }
    }
  }
}
